.main-menu {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;

  &.main-menu-open {
    @include small-desktop {
      .main-menu-links {
        opacity: 1;
        @include transform(skew(0deg, 0deg) translateX(0) translateY(0));
        pointer-events: auto;
      }
      .main-menu-btn {
        .menu-btn-box {
          display: none;

          &.toclose {
            display: block;
          }
        }
      }
      @include min-full-tablet {
        .main-menu-btn {
          display: none;
        }
      }
    }
  }

  &.main-submenu-open {
    @include small-desktop {
      .main-menu-links {
        overflow-y: hidden;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .wrap-main-menu {
    background-color: #fff;
    border-top: 1px solid $color-corp1;
    font-size: 0;
    text-align: right;
    display: flex;
    height: 55px;
  }

  @include min-medium-tablet {
    .wrap-main-menu {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 24px 0;
    }
  }
  @include min-full-tablet {
    bottom: auto;
    top: 0;
    .wrap-main-menu {
      border-top: none;
      border-bottom: 1px solid $color-corp2;
      @include transition($Stransition);
    }
  }
}

.main-menu-logo {
  display: none;
  @include min-full-tablet {
    display: flex;
    @include transition($Stransition);
    .menu-logo-element {
      width: 190px;
      height: auto;
      margin-left: 4px;

      &.white {
        display: none;
        filter: drop-shadow(2px 2px 2px rgba(68, 68, 68, 0.4));
        -o-filter: drop-shadow(2px 2px 2px rgba(68, 68, 68, 0.4));
        -ms-filter: drop-shadow(2px 2px 2px rgba(68, 68, 68, 0.4));
        -webkit-filter: drop-shadow(2px 2px 2px rgba(68, 68, 68, 0.4));
      }
    }
  }
}

.main-menu-links {
  display: none;
  @include small-desktop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: calc(100% - 56px);
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    pointer-events: none;
    opacity: 0;
    @include transform(skew(20deg, 0deg) translateX(-22%) translateY(-20px));
    @include transition($Stransition);
    .wrap-menu-links {
      padding: 25px 40px;
      background-color: #fff;
      text-align: left;
      min-height: 100%;
    }
    @include min-medium-tablet {
      height: calc(100% - 81px);
      .wrap-menu-links {
        padding: 90px 0;
      }
    }
    @include min-full-tablet {
      @include transform(skew(20deg, 0deg) translateX(-22%) translateY(20px));
      top: auto;
      bottom: 0;
      .wrap-menu-links {
        border-bottom: none;
        padding: 55px 0;
      }
    }
  }
  @include min-small-desktop {
    vertical-align: middle;
    .wrap-menu-links {
      font-size: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.menu-links-close {
  display: none;
  @include small-desktop {
    @include min-full-tablet {
      display: block;
      position: absolute;
      top: 20px;
      right: 35px;
      font-size: 0;
      .links-close-text {
        display: inline-block;
        vertical-align: middle;
        font: 500 18px/1 $corp-font1;

        color: $color-corp1;
        padding-right: 15px;
      }
      .links-close-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 2px solid $color-corp1;

        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translateY(-50%) translateX(-50%));
          font-size: 22px;
          color: $color-corp1;
        }
      }
    }
  }
}

.menu-links-list {
  @include small-desktop {
    padding-bottom: 15px;
    @include min-medium-tablet {
      width: 550px;
      margin: 0 auto;
    }
  }
  @include min-small-desktop {
    font-size: 0;
    gap: 15px;
    display: flex;
    align-items: center;
    @include hovers {
      > *:not(:hover) {
        opacity: 0.6;
      }
    }
  }
}

.menu-links-element {
  @include small-desktop {
    display: block;
    .wrap-links-element {
      display: block;
      padding: 15px 0;
      border-bottom: 1px solid $color-lines;
    }
    .links-element-text {
      display: flex;
      font: 500 16px/1 $corp-font-titles;
      align-items: center;
      color: $color-corp1;
    }
    @include min-medium-tablet {
      .wrap-links-element {
        padding: 20px 0;
      }
    }
  }
  @include min-small-desktop {
    display: inline-block;
    &.home-element {
      display: none;
    }
    vertical-align: middle;
    @include transition($Stransition);
    &.home {
      .wrap-links-element {
        &:before {
          content: "\e904";
          display: inline-block;
          font-family: "icomoon";
          font-size: 14px;
          color: $color-corp1;
          @include transition($Stransition);
        }
      }

      .links-element-text {
        display: none;
      }

      @include hovers {
        .wrap-links-element {
          &:before {
            content: "\e905";
          }
        }
      }
    }
    &.dropdown {
      .wrap-links-element {
        position: relative;
        display: block;
      }

      .wrap-links-element {
        &.active {
          &:after {
            @include transform(translateX(-50%) translateY(0) scale(1));
            opacity: 1;
          }
        }
      }

      @include min-full-desktop {
        .wrap-links-element {
          &:after {
            font-size: 8px;
            bottom: 12px;
          }
        }

        .sublinks-element-text {
          &:after {
            color: #fff;
          }
        }
      }
    }
    .wrap-links-element {
      cursor: pointer;
    }
    .links-element-text {
      font: 500 16px/1 $corp-font1;

      color: $color-corp2;
      display: flex;
      align-items: center;
      @include transition($Stransition);
    }
  }
  @include min-medium-desktop {
    .links-element-text {

    }
  }
  @include min-full-desktop {
    &.home {
      .wrap-links-element {
        &:before {
          font-size: 16px;
        }
      }
    }
  }
}

.menu-sublinks-list {
  gap: 14px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  font-size: 0;
  margin: 0 -7px;
  @include min-medium-tablet {
    width: 550px;
    margin: 0 auto;
    gap: 0;
  }
  @include min-small-desktop {
    display: none;
  }
}

.menu-sublinks-list-desktop {
  display: none;
  @include min-small-desktop {
    display: flex;
    gap: 16px;
  }
}

.menu-sublinks-element {
  display: inline-block;
  vertical-align: top;

  .wrap-sublinks-element {
    border-radius: 167px;
    border-width: 1px;
    border-style: solid;
    border-color: $color-corp1;
    color: $color-corp1;
    min-width: 140px;
    padding: 9px 0;
    text-align: center;
    @include transition($Stransition);
    @include mobile {
      min-width: 110px;
    }
  }


  .main-color {
    background-color: $color-corp1;
    border-color: $color-corp1;
    color: #FFFFFF;
  }

  .sublinks-element-text {
    font: 500 16px/1 $corp-font1;

    border-radius: 272px;
    @include transition($Stransition);
  }

  @include min-medium-tablet {
    .wrap-sublinks-element {
      min-width: 175px;
    }
  }
  @include min-small-desktop {
    cursor: pointer;
    @include hovers {
      .wrap-sublinks-element {
        background-color: $color-corp1;
      }
      .sublinks-element-text {
        color: #fff;
      }
    }
  }
}

.main-menu-btn {
  display: none;
  @include small-desktop {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    @include transform(translateY(-50%));
    .menu-btn-box {
      padding: 15px;
      font-size: 0;

      &.toclose {
        display: none;

        .menu-btn-icon {
          position: relative;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          border: 2px solid $color-corp1;
          font-size: 22px;

          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translateY(-50%) translateX(-50%));
          }
        }
      }
    }
    .menu-btn-icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      color: $color-corp1;
    }
    .menu-btn-text {
      display: inline-block;
      vertical-align: middle;
      font: 500 16px/1 $corp-font1;

      color: $color-corp1;
      padding-left: 10px;
    }
    @include min-medium-tablet {
      padding-left: 15px;
    }
    @include min-full-tablet {
      position: relative;
      top: 0;
      @include transform(none);
      display: inline-block;
      vertical-align: middle;
      @include transition($Stransition);
      .menu-btn-box {
        overflow: hidden;

        &.toclose {
          .menu-btn-text {
            padding-top: 8px;
          }
        }
      }
      .menu-btn-icon {
        float: right;
        @include transition($Stransition);
      }
      .menu-btn-text {
        float: left;
        padding: 0 10px 0 0;
        @include transition($Stransition);
      }
    }
  }
}

.main-menu-subtns {
  font-size: 0;
  padding-right: 0;
  gap: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  height: 56px;

  @include min-full-tablet {
    width: inherit;
    justify-content: normal;
  }
}

.menu-subtns-element {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  .wrap-subtns-element {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 2px solid $color-corp1;
    @include transition($Stransition);
  }

  .subtns-element-text {
    position: absolute;
    top: 50%;
    left: 0%;
    @include transform(translateY(-50%));
    text-align: center;
    font: 700 13px/1 $corp-font1;
    color: $color-corp1;

    text-transform: uppercase;
    width: 100%;
    @include transition($Stransition);
  }

  .subtns-element-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translateY(-50%) translateX(-50%));
    font-size: 16px;
    color: $color-corp1;
    @include transition($Stransition);
  }

  @include min-full-tablet {
    .wrap-subtns-element {
      &:after {
        content: "\e90e";
        position: absolute;
        top: calc(100% + 6px);
        left: calc(50% - 2.5px);
        font-family: "icomoon";
        font-size: 5px;
        color: $color-corp1;
        @include transition($Stransition);
      }
    }
  }
  @include hovers {
    .wrap-subtns-element {
      background-color: $color-corp1;
    }
    .subtns-element-text {
      color: #fff;
    }
    .subtns-element-icon {
      color: #fff;
    }
  }
}

.main-submenu {
  display: none;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  pointer-events: none;
  opacity: 0;
  @include transform(skew(20deg, 0deg) translateX(-22%) translateY(-20px));
  @include transition($Stransition);

  &.open {
    opacity: 1;
    pointer-events: auto;
    @include transform(skew(0, 0deg) translateX(0) translateY(0));
  }

  .main-submenu-blackmask {
    display: none;
  }

  .wrap-main-submenu {
    padding: 40px;
    background-color: #fff;
    text-align: left;
    min-height: 100%;
    border-top: 1px solid $color-corp1;
  }

  .main-submenu-title {
    font: 600 16px/1 $corp-font1;
    color: $color-corp1;

    padding-bottom: 13px;
  }

  @include min-medium-tablet {
    .wrap-main-submenu {
      padding: 90px 0;
    }
    .main-submenu-title {
      width: 550px;
      margin: 0 auto;
      padding-bottom: 25px;
    }
  }
  @include min-full-tablet {
    .wrap-main-submenu {
      padding: 55px 0;
      border-bottom: none;
    }
    .main-submenu-title {
      width: 770px;
    }
  }
  @include min-small-desktop {
    top: 81px;
    height: calc(100% - 81px);
    @include transform(translateY(20px));
    transform-origin: top center;
    &.open {
      @include transform(translateY(0));
    }
    .main-submenu-blackmask {
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#010101, 0.5);
      cursor: zoom-out;
    }
    .wrap-main-submenu {
      width: 100%;
      padding: 60px 50px;
      min-height: initial;
    }
    .main-submenu-title {
      width: auto;
    }
  }
  @include min-medium-desktop {
    .wrap-main-submenu {
      padding: 60px 90px;
    }
    .main-submenu-title {
      font-size: 20px;
    }
  }
  @include min-full-desktop {
    .main-submenu-title {
      max-width: 1180px;
    }
  }
}

.main-submenu-close {
  display: none;
  @include small-desktop {
    @include min-full-tablet {
      display: block;
      position: absolute;
      top: 20px;
      right: 35px;
      font-size: 0;
      .submenu-close-text {
        display: inline-block;
        vertical-align: middle;
        font: 500 18px/1 $corp-font1;

        color: $color-corp1;
        padding-right: 15px;
      }
      .submenu-close-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 2px solid $color-corp1;

        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translateY(-50%) translateX(-50%));
          font-size: 22px;
          color: $color-corp1;
        }
      }
    }
  }
}

.main-submenu-list {
  @include min-medium-tablet {
    width: 550px;
    margin: 0 auto;
  }
  @include min-full-tablet {
    width: 820px;
    font-size: 0;
  }
  @include min-small-desktop {
    width: auto;
    margin: 0 -25px;
    @include hovers {
      > *:not(:hover) {
        opacity: 0.6;
      }
    }
  }
  @include min-full-desktop {
    max-width: 1230px;
    margin: 0 auto;
  }
}

.main-submenu-element {
  display: block;

  .wrap-submenu-element {
    padding: 13px 0;
    border-top: 1px solid $color-lines;
  }


  @include hovers {
    background-color: rgba(46, 56, 98, .1);
  }

  .submenu-element-text {
    font: 500 16px/1 $corp-font1;
    color: $color-corp2;

  }

  @include min-medium-tablet {
    .wrap-submenu-element {
      padding: 20px 0;
    }
  }
  @include min-full-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 25px;
    .wrap-submenu-element {
      padding: 23px 0;
    }
  }
  @include min-small-desktop {
    width: 33.33%;
    @include transition($Stransition);
    .wrap-submenu-element {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $color-corp2;
        @include transition($Stransition);
      }
    }
    @include hovers {
      .wrap-submenu-element {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

.var-nav-white {
  @include min-full-tablet {
    .main-menu {
      .wrap-main-menu {
        background-color: transparent;
        border-color: transparent;
        padding-top: 20px;
      }
    }
    .main-menu-logo {
      .menu-logo-element {
        display: none;

        &.white {
          display: block;
        }
      }
    }
    .main-menu-btn {
      .menu-btn-icon {
        color: #fff;

      }

      .menu-btn-text {
        color: #fff;

      }
    }
    .menu-subtns-element {
      .wrap-subtns-element {
        border-color: #fff;
        @include box-shadow;

        &:after {
          color: #fff;

        }
      }

      .subtns-element-text {
        color: #fff;

      }

      .subtns-element-icon {
        color: #fff;

      }
    }
  }
  @include min-small-desktop {
    .menu-links-element {
      &.home-element {
        display: none;
      }

      .links-element-text {
        display: flex;
        align-items: center;
        color: #fff;
        height: 25px;
      }
    }

    .menu-sublinks-element {

      .wrap-sublinks-element {
        &.white-color {
          border-color: #FFFFFF !important;
          color: #FFFFFF !important;
        }
      }

      .sublinks-element-text {
        color: #fff;
      }

      @include hovers {
        .wrap-sublinks-element {
          background-color: #fff;
        }
        .sublinks-element-text {
          color: $color-corp1;
          text-shadow: none;
        }
      }
    }
    .menu-subtns-element {
      @include hovers {
        .wrap-subtns-element {
          background-color: #fff;
        }
        .subtns-element-text {
          color: $color-corp1;
          text-shadow: none;
        }
        .subtns-element-icon {
          color: $color-corp1;
          text-shadow: none;
        }
      }
    }
  }
}

.inlinks-menu-detector {
  min-height: 68px;
}

.inlinks-menu {
  padding-bottom: 15px;
  background-color: #fff;

  &.fixed {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    @include min-full-tablet {
      top: 80px;
    }
  }

  .wrap-inlinks-menu {
    position: relative;
    padding: 15px 0 0 35px;
    background-color: $color-corp2;
  }

  @include min-medium-tablet {
    .wrap-inlinks-menu {
      padding: 15px 0 0 50px;
    }
  }
  @include min-full-tablet {
    .wrap-inlinks-menu {
      padding: 15px 0 0 80px;
    }
  }
  @include min-full-desktop {
    .wrap-inlinks-menu {
      padding: 15px 0 0 150px;
    }
  }
}

.inlinks-menu-list {
  font-size: 0;
}

.inlinks-menu-element {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  &.active {
    .wrap-inlinks-element {
      background-color: #fff;

      &:after {
        opacity: 1;
      }
    }

    .inlinks-element-text {
      color: $color-corp1;
    }
  }

  .wrap-inlinks-element {
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 15px 22px 12px;
    @include transition($Stransition);

    &:after {
      content: "\e90c";
      position: absolute;
      bottom: 2px;
      left: calc(50% - 5.5px);
      font-family: "icomoon";
      font-size: 7px;
      color: $color-corp2;
      opacity: 0;
      @include transition($Stransition);
    }
  }

  .inlinks-element-text {
    font: 600 14px/1 $corp-font1;
    color: #fff;
    @include transition($Stransition);
    text-align: center;
  }
}
