@import "variables";
@import "reset";
@import "global";
@import "menu";

.intern {
  @include min-full-tablet {
    padding-top: 80px;
  }
}

.aside-action {
  @include full-tablet {
    position: fixed;
    z-index: 105;
    left: 50%;
    bottom: 8px;
    @include transform(translateX(-50%));
    .wrap-aside-action {
    }
    .aside-action-text {
      display: none;
    }
    .aside-action-btn {
      display: block;
      width: 130px;
      padding: 9px 0;
      border-radius: 4px;
      border: 2px solid $color-corp1;
      text-align: center;

      .action-btn-text {
        font: 500 16px/1 $corp-font1;
        color: $color-corp1;
      }
    }
    @include mobile {
      .aside-action-btn {
        width: 105px;

        .action-btn-text {
          font-size: 14px;
        }
      }
    }
    @include min-medium-tablet {
      bottom: 15px;
      .aside-action-btn {
        width: 240px;
        padding: 13px 0;

        .action-btn-text {
          font-size: 20px;
        }
      }
    }
  }
  @include min-full-tablet {
    position: fixed;
    z-index: 10;
    right: 40px;
    bottom: 45px;
    width: 210px;
    .wrap-aside-action {
      padding: 15px 25px;
      border-radius: 3px;
      background-color: rgba(#000000, 0.5);
    }
    .aside-action-text {
      font: 500 16px/1.1 $corp-font1;

      color: #fff;
      padding-bottom: 15px;
    }
    .aside-action-btn {
      display: block;
      padding: 9px 0;
      border-radius: 4px;
      background-color: $color-corp2;
      text-align: center;
      @include transition($Stransition);

      .action-btn-text {
        font: 500 18px/1 $corp-font1;

        color: #fff;
      }
    }
  }
}

.home-module {
  .wrap-home-module {
    position: relative;
    min-height: 612px;
  }

  @include mobile {
    .wrap-home-module {
      min-height: 513px;
    }
  }
  @include min-medium-tablet {
    .wrap-home-module {
    }
  }
  @include min-full-tablet {
    .wrap-home-module {
    }
  }
}

.home-module-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;

  .wrap-home-logo {
    padding: 30px 0 0;
    text-align: center;
  }

  .home-logo-graphic {
    display: inline-block;
    width: 240px;
    height: auto;
    filter: drop-shadow(2px 2px 2px rgba($color-corp2, 0.4));
    -o-filter: drop-shadow(2px 2px 2px rgba($color-corp2, 0.4));
    -ms-filter: drop-shadow(2px 2px 2px rgba($color-corp2, 0.4));
    -webkit-filter: drop-shadow(2px 2px 2px rgba($color-corp2, 0.4));
  }

  @include min-medium-tablet {
    .wrap-home-logo {
      padding: 50px 0 0;
    }
    .home-logo-graphic {
      width: 385px;
    }
  }
  @include min-full-tablet {
    display: none;
  }
}

.home-module-slider {
  position: absolute !important;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 730px;
}

.home-slider-element {
  height: 100%;

  .wrap-slider-element {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .wrap-slider-element:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 10;
    background-image: linear-gradient(
      180deg,
      #2e3862 0%,
      rgba(94, 114, 200, 0) 186.03%
    );
  }

  .slider-element-video {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.15);
    }

    &.slider-element-video-mobile {
      display: block;

      .slider-element-video_element {
        height: 110%;
        position: absolute;
        width: auto;
      }

      @media (min-width: 452px) {
        .slider-element-video_element {
          height: unset;
          width: 100%;
        }
      }

      @include min-small-desktop {
        display: none;
      }
    }

    &.slider-element-video-desktop {
      display: none;
      @include min-small-desktop {
        display: block;
      }

      .slider-element-video_element {
        position: absolute;
        width: inherit;
      }
    }
  }

  .slider-element-info-desktop {
    display: none;
    @include min-small-desktop {
      display: block;
    }
  }

  .slider-element-info-mobile {
    display: block;
    @include min-small-desktop {
      display: none;
    }
  }

  .slider-element-info {
    position: absolute;
    z-index: 11;
    left: 35px;
    bottom: 55px;
    padding-left: 25px;

    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      width: 15px;
      height: 1px;
      background-color: $color-corp1;
    }

    .slider-info-text {
      font: 500 15px/1.1 $corp-font1;

      color: #fff;
    }

    .slider-info-subtext {
      font: 400 12px/1.1 $corp-font1;

      color: #fff;
      padding-top: 5px;
    }

    @include full-tablet {
      left: 0;
      bottom: 180px;
      width: 100%;
      text-align: center;
      padding-left: 0;
      &:before {
        content: none;
      }
      .slider-info-subtext {
        width: 260px;
        display: inline-block;
        border-bottom: 1px solid $color-corp1;
        padding-bottom: 15px;
      }
    }
    @include medium-tablet {
      bottom: 205px;
    }
    @include small-tablet {
      bottom: 120px;
      .slider-info-text {
        width: 260px;
        display: inline-block;
        border-top: 1px solid $color-corp1;
        padding-top: 30px;
      }
      .slider-info-subtext {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.home-module-info {
  position: absolute;
  z-index: 11;
  top: 309px;
  left: 0;
  width: 100%;

  .wrap-home-info {
    padding: 0 10px;
    text-align: center;
  }

  .home-info-title {
    font: 600 30px/1 $corp-font-titles;
    line-height: 40px;
    color: #fff;
    padding-bottom: 32px;
  }

  .home-info-text {
    font: 400 16px/1.2 $corp-font1;
    color: #fff;
    @include transition($Stransition);
  }

  .home-info-scroll {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 3px solid #fff;
    @include box-shadow;
    cursor: pointer;
    @include transition($Stransition);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateY(-45%) translateX(-50%));
      font-size: 10px;
      color: #fff;
      @include transition($Stransition);
    }

    .info-scroll-tag {
      position: absolute;
      left: 50%;
      bottom: calc(100% + 20px);
      width: 130px;
      @include transform(translateX(-50%) translateY(10px) scale(0.8));
      opacity: 0;
      padding: 6px 0;
      text-align: center;
      background-color: $color-corp2;
      border-radius: 2px;
      font: 500 13px/1 $corp-font1;

      color: #fff;
      @include transition($Stransition);

      &:after {
        content: "\e90e";
        position: absolute;
        top: calc(100% - 2px);
        left: calc(50% - 4px);
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
      }
    }
  }

  @include small-tablet {
    .home-info-text {
      padding-bottom: 120px;
    }
  }
  @include min-medium-tablet {
    .wrap-home-info {
      padding: 0 65px 25px;
    }
    .home-info-text {
      font-size: 25px;
      padding-bottom: 20px;
      line-height: 49px;
    }
  }
  @include min-full-tablet {
    .wrap-home-info {
      padding: 0 270px 45px;
    }
    .home-info-title {
      font-size: 48px;
      line-height: 63px;
    }
  }
}

.gallery-info-list {
  .wrap-video {
    z-index: 2;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;

    .wrap-box {
      justify-content: center;
      display: flex;
      @include mobile {
        margin-top: 110px;
      }
      @include min-small-tablet {
        margin-top: 0;
      }

      @include min-medium-tablet {
        margin-top: -30px;
      }

      @include min-full-tablet {
        margin-top: 75px;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
      }
    }

    .box-video {
      background-color: #ffffff;
      box-shadow: 14px 14px 40px 0px rgba(0, 0, 0, 0.102);
      border-radius: 16px;
      padding: 40px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .box-video-divider {
        border-color: $color-lines-new2;
        margin: 24px 0;
        width: 100%;
        @include min-full-tablet {
          height: 100%;
          margin: 0 48px;
          width: inherit;
        }
      }

      .video-slogan {
        background-image: url("../assets/img/septeo_gray.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 234px 239px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        span {
          font: 600 24px/1 $corp-font1;
          color: $color-corp2;
          line-height: 42px;
          text-align: center;
        }
      }

      .mini-video {
        width: 250px;
        height: 280px;
        cursor: pointer;
      }

      @include min-small-tablet {
        .mini-video {
          width: 350px;
        }
      }

      @include min-medium-tablet {
        .mini-video {
          width: 498px;
        }
      }

      @include min-full-tablet {
        flex-direction: row;
        padding: 48px;

        .mini-video {
        }

        .video-slogan {
          span {
            font-size: 28px;
          }
        }
      }
    }
  }

  .gallery-info {
    &:nth-child(odd) {
      background-color: $color-background-new;
    }

    &:nth-child(even) {
      background-color: #ffffff;
    }
  }
}

.gallery-info {
  .wrap-gallery-info {
    padding: 45px 0;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    @include min-medium-tablet {
      padding: 60px 0;
    }
  }

  @include min-medium-tablet {
    .wrap-gallery-info {
      padding: 60px 0;
    }
  }
  @include min-full-tablet {
    @include small-desktop {
      .wrap-gallery-info {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }
  @include min-small-desktop {
    &.var-info-left {
      .wrap-gallery-info {
        flex-direction: row-reverse;
      }
    }
    &.var-info-right {
      .wrap-gallery-info {
        flex-direction: row;
      }
    }
    .wrap-gallery-info {
      &:before,
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }
}

.gallery-info-multimedia {
  width: 250px;
  height: 183px;

  .wrap-gallery-multimedia {
    width: 100%;
    height: 100%;
  }

  .gallery-multimedia-img {
    width: 100%;
    height: 100%;
  }
}

@include min-small-tablet {
  .gallery-info-multimedia {
    width: 410px;
    height: 300px;
  }
}

.gallery-info-content {
  display: flex;
  align-items: center;

  .gallery-content-title {
    font: 600 35px/1 $corp-font-titles;
    color: $color-corp2;
    margin: 15px 0;
  }

  .gallery-content-subtitle {
    font: 600 20px/1 $corp-font1;
    line-height: 30px;
    color: $color-corp1;
    margin-bottom: 20px;
  }

  .gallery-content-text {
    font: 400 20px/1 $corp-font1;

    color: $color-corp2;
    line-height: 30px;

    ul {
      padding-left: 5px;

      li {
        margin-left: 10px;
      }

      li::before {
        content: "•";
        color: $color-corp2;
        position: relative;
        right: 10px;
      }
    }
  }

  @include min-small-desktop {
    font-size: 0;
    .gallery-content-title {
      margin-bottom: 30px;
    }
    .gallery-content-text,
    .gallery-content-subtitle {
      max-width: 776px;
    }
  }
}

.info-banner {
  background-color: $color-corp2;

  .wrap-info-banner {
    padding: 40px 0;
    text-align: center;
  }

  .info-banner-title {
    font: 600 38px/1 $corp-font-titles;
    @include line(50, 42);
    color: #fff;
  }

  .info-banner-img {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 30px 0;
  }

  .info-banner-subtitle {
    font: 600 18px/1 $corp-font1;

    @include line(26, 18);
    color: #fff;
    padding-bottom: 20px;
    width: 100%;
  }

  .info-banner-text {
    font: 400 19px/1 $corp-font1;
    color: #fff;
    line-height: 30px;
  }

  .info-banner-btn {
    display: inline-block;

    .banner-btn-text {
      position: relative;
      font: 400 22px/1 $corp-font1;

      color: $color-corp2;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-corp2;
      @include transition($Stransition);

      &:after {
        content: "\e906";
        position: absolute;
        left: calc(100% + 10px);
        top: 10px;
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
        @include transition($Stransition);
      }
    }

    @include hovers {
      .banner-btn-text {
        color: #fff;
        border-color: #fff;

        &:after {
          left: calc(100% + 12px);
          color: #fff;
        }
      }
    }
  }

  @include min-medium-tablet {
    .wrap-info-banner {
      padding: 55px 0;
    }
    .info-banner-img {
      max-width: 555px;
      margin: 60px 0;
    }
    .info-banner-subtitle {
      font-size: 20px;
      padding-bottom: 20px;
    }
    .info-banner-text {
      font-size: 20px;
    }
  }
  @include min-full-tablet {
    .wrap-info-banner {
      width: 790px;
      margin: 0 auto;
    }
  }
  @include min-small-desktop {
    .wrap-info-banner {
      width: 1188px;
      padding: 90px 0;
    }
  }
  @include min-full-desktop {
    .wrap-info-banner {
      padding: 120px 0;
    }
  }
}

.info-data {
  background: linear-gradient(180deg, #ffffff 19.64%, #eff1f9 73.09%);

  .wrap-info-data {
    padding: 40px 0;
    text-align: center;
  }

  .info-data-title {
    font: 600 35px/1 $corp-font-titles;
    color: $color-corp2;
    margin-bottom: 40px;
  }

  @include min-medium-tablet {
    .info-data-title {
      margin-bottom: 60px;
    }
  }
  @include min-full-tablet {
    .wrap-info-data {
      padding: 70px 0;
    }
  }
  @include min-small-desktop {
    .wrap-info-data {
      padding: 90px 0;
    }
  }
  @include min-full-desktop {
    .wrap-info-data {
      padding: 110px 0;
    }
  }
}

.info-data-list {
  font-size: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 67px;
  align-items: center;

  @include min-full-tablet {
    flex-direction: row;
  }
}

.info-data-element {
  background: #ffffff;
  width: 250px;
  height: 250px;
  padding: 80px 40px 80px 40px;
  gap: 20px;
  border-radius: 19.94px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 25px 0px rgba(46, 56, 98, 0.2);

  .data-element-num {
    font: 600 35px/1 $corp-font-titles;
    color: $color-corp2;

    .highlight {
      color: $color-corp1;
    }
  }

  .data-element-title {
    padding-top: 20px;
    font: 400 20px/1 $corp-font1;
    color: $color-corp2;
    text-wrap: nowrap;
  }

  .data-element-text {
    padding-top: 10px;
    font: 400 14px/1 $corp-font1;

    color: $color-corp2;
  }

  @include min-medium-tablet {
    vertical-align: top;
    padding: 0 35px 0;
  }
}

.comments-banner {
  background-color: #ffffff;
  overflow: hidden;

  .wrap-comments-banner {
    padding: 40px 0;
    text-align: center;
    position: relative;
  }

  .comments-banner-title {
    font: 600 35px/1 $corp-font1;
    color: $color-corp2;
    margin-bottom: 40px;
  }

  @include min-medium-tablet {
    .wrap-comments-banner {
      padding: 70px 0;
    }
    .comments-banner-title {
      margin-bottom: 60px;
    }
  }
}

.slick-track {
  display: flex;
}

.comments-slick-btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: center;
  margin-top: 40px;

  .arrow-btn {
    border: 1px solid $color-corp2;
    color: $color-corp2;
    background-color: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    display: flex;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0%;
    cursor: pointer;

    .arrow-left {
      transform: rotate(-180deg);
    }
  }
}

@include min-medium-tablet {
  .comments-slick-btns {
    bottom: 52px;
    position: absolute;
    right: 25px;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.comments-banner-box {
  .slick-prev:before {
    content: "<";
    color: red;
    font-size: 30px;
  }

  .slick-next:before {
    content: ">";
    color: red;
    font-size: 30px;
  }

  .slick-dots {
    display: none;
  }

  @include min-medium-tablet {
    .slick-dots {
      margin: 40px 6px 0;
      display: flex;
      justify-content: center;
      list-style-type: none;

      button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: #f2f4f7;
        margin-left: 6px;
        margin-right: 6px;
        text-indent: -9999px;
      }

      li.slick-active button {
        background-color: $color-corp2;
      }
    }
  }
  @include min-full-desktop {
    .slick-dots {
      margin: 80px 6px 0;
    }
  }
}

.comments-banner-element {
  padding: 24px;
  background: rgba(239, 241, 249, 1);
  border-radius: 20px;
  margin: 0 15px;
  display: flex !important;
  align-items: center;
  height: auto;

  .wrap-comments-element {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .comments-element-text {
    font: 400 16px/1 $corp-font-titles;
    color: $color-corp2;
    text-align: left;
    line-height: 144%;
  }
}

@include min-medium-tablet {
  .comments-banner-element {
    padding: 32px;
    margin: 0 33.5px;
  }
}

.comments-element-user {
  display: flex;
  margin-top: 32px;

  .img-circular-container {
    display: flex;
    align-items: flex-start;

    .comments-user-icon {
      position: relative;
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  .comments-wrap-data {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    .comments-user-name {
      font: 600 14px/1 $corp-font1;
      @include line(21, 26);
      color: $color-corp2;
      text-align: left;
    }

    .comments-user-info {
      padding-top: 9px;
      font: 400 14px/1 $corp-font1;
      color: $color-corp2;
      text-align: left;
    }
  }
}

.inlinks-banner {
  background-color: $color-background-new;
  color: $color-corp2;

  .inlinks-banner-title {
    color: $color-corp2;
  }

  .inlinks-banner-text {
    color: $color-corp2;
  }

  .wrap-inlinks-banner {
    padding: 60px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .inlinks-banner-title {
    font: 600 35px/1 $corp-font-titles;
    @include line(50, 42);
    color: $color-corp2;
    padding-bottom: 25px;
  }

  .inlinks-banner-text {
    font: 600 20px/1 $corp-font1;
    @include line(26, 19);
    color: $color-corp2;

    small {
      font-size: 14px;
      line-height: 1;
    }
  }

  .inlinks-banner-small-text {
    font: 400 14px/1 $corp-font1;
  }

  @include min-medium-tablet {
    .inlinks-banner-title {
      padding-bottom: 60px;
    }
    .wrap-inlinks-banner {
      padding: 120px 0;
    }
  }
  @include min-full-tablet {
    .wrap-inlinks-banner {
      margin: 0 auto;
    }
  }
}

.inlinks-banner-btns {
  padding: 20px;
  font-size: 0;

  .banner-btns-element {
    padding: 16px 22px;
  }

  @include min-medium-tablet {
    @include small-desktop {
      .banner-btns-element {
        .btn-corp-text {
          min-width: 180px;
        }
      }
    }
  }

  .inlinks-btn {
    padding: 10px 25px;
    border-radius: 166.97px;
    background-color: $color-corp1;
    color: #ffffff;
    font: 600 16px/1 $corp-font-titles;
    line-height: 24px;
  }
}

.main-footer {
  background-color: $color-corp2;

  .wrap-main-footer {
    padding: 70px 0 90px;
  }

  @include min-medium-tablet {
    .wrap-main-footer {
      padding: 70px 0 120px;
    }
  }
  @include min-full-tablet {
    .wrap-main-footer {
      padding: 70px 0 50px;
      border-bottom: 3px solid $color-corp2;
    }
  }
}

.main-footer-top {
  position: absolute;
  top: 0;
  left: 50%;
  @include transform(translateX(-50%) translateY(-50%));
  padding: 12px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  .footer-top-tag {
    display: none;
  }

  .footer-top-icon {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $color-corp1;
    @include transition($Stransition);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateY(-50%) translateX(-50%));
      font-size: 10px;
      color: $color-corp1;
      @include transition($Stransition);
    }
  }

  @include min-full-tablet {
    left: auto;
    right: 0;
    @include transform(translateY(-50%));
  }
  @include min-small-desktop {
    .footer-top-tag {
      display: block;
      position: absolute;
      bottom: 100%;
      left: calc(50% - 22.5px);
      width: 45px;
      font: 500 13px/1 $corp-font1;
      color: #fff;

      padding: 7px 0;
      text-align: center;
      background-color: $color-corp2;
      border-radius: 2px;
      @include transition($Stransition);
      opacity: 0;
      pointer-events: none;
      @include transform(translateY(0) scale(0.8));

      &:after {
        content: "\e90e";
        position: absolute;
        left: calc(50% - 4px);
        top: calc(100% - 2px);
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
      }
    }
    @include hovers {
      .footer-top-tag {
        opacity: 1;
        @include transform(translateY(-12px) scale(1));
      }
      .footer-top-icon {
        background-color: $color-corp2;
        border-color: transparent;

        &:before {
          color: #fff;
        }
      }
    }
  }
}

.main-footer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  .wrap-footer-info {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .wrap-footer-info-boxes {
    display: flex;
    flex-direction: column;
  }

  .wrap-footer-links {
    display: flex;
    gap: 18px;
    margin: 20px 0;

    .footer-rrss {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: #ffffff;
    }

    .rrss-logo {
      height: 17px;
      width: 100%;
    }
  }

  @include min-medium-tablet {
    flex-direction: row;
    .wrap-footer-info {
      flex-direction: row;
    }
    .wrap-footer-info-boxes {
      gap: 60px;
      height: 195px;
      flex-direction: row;
    }
    .wrap-footer-links {
      margin: 40px 0 0;
    }
  }

  @include min-medium-tablet {
    .wrap-footer-info {
      font-size: 0;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;

      &:after {
        left: 35px;
        width: calc(100% - 70px);
      }
    }
  }
  @include min-small-desktop {
    .wrap-footer-info {
      padding: 0;

      &:after {
        content: none;
      }
    }
    @include full-desktop {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .wrap-footer-info {
        margin: 0 -15px;
        padding-right: 15px;
      }
    }
  }
}

.info-box-text {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font: 400 14px/1 $corp-font1;
  color: #ffffff99;
  margin-bottom: 20px;
}

.footer-info-box {
  display: flex;

  .info-box-title {
    font: 600 16px/1 $corp-font1;
    color: #ffffff;

    padding-bottom: 24px;
  }

  span {
    margin-bottom: 5px;
  }
}

.main-footer-sign {
  .footer-hr {
    color: $color-lines-new;
    margin: 24px 0;
  }

  .footer-septeo {
    margin: 24px 0;
  }

  @include min-full-tablet {
    .footer-hr {
      margin: 64px 0;
    }
    .footer-septeo {
      margin-top: 50px;
    }
  }

  .footer-sign-text {
    font: 600 16px/1 $corp-font1;
    color: #ffffff;

    padding: 10px 0;

    &:nth-child(2) {
      border-top: 1px solid $color-lines;
    }
  }

  @include min-medium-tablet {
    .wrap-footer-sign {
      font-size: 0;
      margin: 0 -20px;
    }
    .footer-sign-text {
      display: inline-block;
      vertical-align: middle;
      border-left: 1px solid $color-lines;
      padding: 0 20px;

      &:first-child {
        border-left: none;
      }

      &:nth-child(2) {
        border-top: none;
      }
    }
  }
  @include min-small-desktop {
    clear: both;
  }

  .wrap-footer-end {
    display: flex;
    flex-direction: column;
  }

  @include min-medium-tablet {
    .wrap-footer-end {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.responsive-header {
  .wrap-responsive-header {
    text-align: center;
    padding: 8px 0 7px;
    border-bottom: 1px solid $color-corp2;
  }

  .responsive-header-logo {
    display: inline-block;
    width: 165px;
    height: auto;
  }

  @include min-medium-tablet {
    .wrap-responsive-header {
      padding: 17px 0 16px;
    }
    .responsive-header-logo {
      width: 190px;
    }
  }
  @include min-full-tablet {
    display: none;
  }
}

.intro-header {
  position: relative;
  background-color: $color-corp2;

  &.var-menu-space {
    .wrap-intro-header {
      padding-bottom: 0;
    }

    @include min-medium-tablet {
      .wrap-intro-header {
        padding-bottom: 30px;
      }
    }
  }

  .wrap-intro-header {
    padding: 20px 0;
  }

  .intro-header-title {
    font: 600 28px/1 $corp-font-titles;
    color: #fff;
    margin-bottom: 20px;
  }

  .intro-header-text {
    font: 400 20px/1 $corp-font1;
    color: white;

    @include line(24, 20);
    padding-top: 10px;
  }

  @include min-medium-tablet {
    .big-wrapper {
      padding: 0 50px;
    }
    .wrap-intro-header {
      padding: 45px 0;
    }
    .intro-header-text {
      @include line(34, 28);
    }
  }
  @include min-full-tablet {
    .big-wrapper {
      padding: 0 80px;
    }
  }
  @include min-full-desktop {
    .big-wrapper {
      padding: 0 150px;
    }
  }
}

.intro-header-breadcrumbs {
  display: none;
  @include min-full-tablet {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    .wrap-header-breadcrumbs {
      font-size: 0;
    }
    .header-breadcrumbs-element {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font: 500 14px/1 $corp-font1;
      color: #ffffff;

      padding: 0 20px 4px;
      @include transition($Stransition);

      &.active {
        &:after {
          width: calc(100% - 40px);
        }
      }

      &:before {
        content: "/";
        position: absolute;
        left: 0;
        top: 3px;
        font: 500 12px/1 $corp-font1;
        color: #ffffff;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 0;
        height: 1px;
        color: #ffffff;
        background-color: #ffffff;
        @include transition($Stransition);
      }

      &:first-child {
        &:before {
          content: none;
        }
      }

      @include hovers {
        &:after {
          width: calc(100% - 40px);
        }
      }
    }
  }
}

.gallery-details {
  .wrap-details-multimedia {
    height: 100%;
    width: 100%;
  }

  &.var-info-left {
    @include min-small-desktop {
      .gallery-details-multimedia {
        right: 0;

        .wrap-details-multimedia {
          padding-right: 80px;
        }

        .details-multimedia-btn {
          left: calc(50% - 30px);
        }
      }
    }
    @include min-full-desktop {
      .gallery-details-multimedia {
        .wrap-details-multimedia {
          padding-right: 150px;
        }

        .details-multimedia-btn {
          left: calc(50% - 75px);
        }
      }
    }
  }

  &.var-info-right {
    @include min-small-desktop {
      .gallery-details-content {
        margin: 0 0 0 auto;
      }
      .gallery-details-multimedia {
        left: 0;

        .wrap-details-multimedia {
          padding-left: 80px;
        }

        .details-multimedia-btn {
          left: calc(50% + 30px);
        }
      }
    }
    @include min-full-desktop {
      .gallery-details-multimedia {
        .details-multimedia-btn {
          left: calc(50% + 75px);
        }
      }
    }
  }

  .wrap-gallery-details {
    padding: 20px 35px;
  }

  @include min-medium-tablet {
    .wrap-gallery-details {
      padding: 30px 50px;
    }
  }
  @include min-full-tablet {
    @include small-desktop {
      .wrap-gallery-details {
        max-width: 765px;
        margin: 0 auto;
      }
    }
  }
  @include min-small-desktop {
    .wrap-gallery-details {
      padding: 0;
      position: relative;
    }
  }
}

.gallery-details-content {
  .wrap-details-content {
    padding-top: 20px;
  }

  .details-content-intro {
    padding-bottom: 35px;
    border-bottom: 1px solid $color-lines;
  }

  .details-content-title {
    font: 600 28px/1 $corp-font-titles;
    color: $color-corp2;
    padding-bottom: 10px;
  }

  .details-content-subtitle {
    position: relative;
    font: 500 16px/1 $corp-font1;
    color: $color-corp1;
    padding-bottom: 10px;

    &:before {
      content: "\e90f";
      position: absolute;
      right: calc(100% + 5px);
      top: 3px;
      font-family: "icomoon";
      font-size: 7px;
      color: $color-corp2;
    }
  }

  .details-content-text {
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;

    @include line(26, 19);
    white-space: pre-line;
  }

  .details-content-btn {
    margin-top: 15px;
  }

  .details-content-point {
    padding: 30px 0 30px 15px;
    border-bottom: 1px solid $color-lines;
  }

  @include min-medium-tablet {
    .wrap-details-content {
      padding: 35px 30px 0;
    }
    .details-content-intro {
      padding-bottom: 50px;
    }
    .details-content-title {
      padding-bottom: 20px;
    }
    .details-content-subtitle {
      font-size: 18px;
      padding-bottom: 15px;
    }
    .details-content-point {
      padding: 40px 0 40px 15px;
    }
  }
  @include min-small-desktop {
    width: 50%;
    .wrap-details-content {
      padding: 80px 80px 60px;
    }
  }
  @include min-full-desktop {
    width: 50%;
    .wrap-details-content {
      padding: 90px 150px 80px;
    }
  }
}

.gallery-details-multimedia {
  .details-multimedia-box {
    background-color: $color-background-new;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
  }

  .details-multimedia-graphic {
    height: 80%;
    width: 80%;
  }

  .details-multimedia-img {
    top: 0;
    left: 0;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 800px;
  }

  .details-multimedia-btn {
    display: table;
    margin: 12px auto 0;
  }

  @include min-medium-tablet {
    .wrap-details-multimedia {
      position: relative;
    }
    .details-multimedia-btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0;
      @include transform(translateY(50%) translateX(-50%));
    }
  }
  @include min-small-desktop {
    position: absolute;
    top: 0;
    width: 50%;
    &.fixed {
      position: fixed;
      top: 130px;

      &.stop {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    }
    .wrap-details-multimedia {
      padding: 60px 0;
      height: 100vh;
    }
    .details-multimedia-graphic {
      max-width: 350px;
    }
    .details-multimedia-btn {
      bottom: 60px;
      left: calc(50% - 40px);
    }
    .details-multimedia-img {
      height: auto;
    }
  }
  @include min-full-desktop {
    .wrap-details-multimedia {
      padding: 80px 0;
    }
    .details-multimedia-btn {
      bottom: 80px;
    }
  }
}

.graphics-module {
  .wrap-graphics-module {
    padding: 20px 35px;
  }

  @include min-medium-tablet {
    .wrap-graphics-module {
      padding: 30px 80px;
    }
  }
  @include min-small-desktop {
    .wrap-graphics-module {
      padding: 45px 80px;
      font-size: 0;
    }
  }
}

.graphics-element-info {
  .graphics-info-title {
    font: 600 28px/1 $corp-font-titles;
    color: $color-corp2;
    @include line(42, 38);
    padding-bottom: 10px;
  }

  .graphics-info-text {
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;

    @include line(26, 18);
  }

  @include min-medium-tablet {
    .wrap-graphics-info {
      padding-bottom: 10px;
    }
    .graphics-info-title {
      padding-bottom: 20px;
    }
    .graphics-info-text {
      max-width: 650px;
    }
  }
  @include min-small-desktop {
    display: inline-block;
    vertical-align: top;
    width: 300px;
  }
}

.graphics-element-list {
  @include min-medium-tablet {
    .wrap-graphics-list {
      font-size: 0;
      margin: 0 -35px;
    }
  }
  @include min-small-desktop {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 300px);
    padding-left: 60px;
    margin-top: -20px;
    @include full-desktop {
      .wrap-graphics-list {
        margin: 0 -20px;
      }
    }
  }
  @include min-full-desktop {
    margin-top: -30px;
    padding-left: 80px;
  }
}

.graphics-list-element {
  .wrap-list-element {
    padding: 35px 0;
    text-align: center;
    border-bottom: 1px solid $color-lines;
    min-height: 143.05px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .graphics-list-img {
    display: inline-block;
    max-height: 70.05px;
    height: auto;
    width: 195px;
  }

  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 35px;
  }
  @include min-full-tablet {
    width: 33.33%;
  }
  @include min-small-desktop {
    .wrap-list-element {
      @include hovers {
        .graphics-list-img {
          filter: grayscale(0%);
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          -ms-filter: grayscale(0%);
          -o-filter: grayscale(0%);
        }
      }
    }
    .graphics-list-img {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      @include transition($Stransition);
    }
    @include full-desktop {
      padding: 0 20px;
    }
  }
  @include min-full-desktop {
    .wrap-list-element {
      padding: 40px 0;
      min-height: 151.05px;
    }
    .graphics-list-img {
      width: 240px;
    }
  }
}

.prices-module {
  .prices-module-title {
    font: 500 34px/1 $corp-font-titles;
    color: $color-titles;

    @include line(40, 34);
    padding-top: 30px;
    text-align: center;
  }

  @include min-medium-tablet {
    .wrap-prices-module {
      margin: 0 auto;
    }
    .prices-module-title {
      font-size: 45px;
    }
  }
  @include min-full-tablet {
    .big-wrapper {
      padding: 0 80px;
    }
    .wrap-prices-module {
      max-width: initial;
    }
  }
  @include min-full-desktop {
    .big-wrapper {
      padding: 0 150px;
    }
    .wrap-prices-module {
      max-width: 1460px;
      margin: 0 auto;
    }
  }
}

.prices-module-filter {
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px;

  .wrap-prices-filter {
    padding: 20px 0 20px 0;
  }

  @include min-medium-tablet {
    .wrap-prices-filter {
      justify-content: center;
      padding: 20px 0px;
    }
  }
  @include min-full-tablet {
    padding-bottom: 30px;
    flex-direction: row;
    .wrap-prices-filter {
      padding: 30px 0px;
      font-size: 0;
    }
  }
  @include min-small-desktop {
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

.prices-filter-header {
  padding-bottom: 12px;

  .prices-filter-title {
    font: 600 28px/1 $corp-font-titles;
    color: $color-corp2;

    @include line(40, 34);
    padding-bottom: 5px;
  }

  .prices-filter-subtitle {
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;
  }

  @include min-full-tablet {
    padding: 0 40px 0 0;
    display: inline-block;
    vertical-align: middle;
  }
}

.prices-filter-form {
  .prices-filter-field {
    position: relative;
    border-radius: 2px;
    border: 1px solid $color-corp2;
    height: 50px;

    &:after {
      content: "\e90c";
      position: absolute;
      top: calc(50% - 5px);
      right: 15px;
      font-family: "icomoon";
      font-size: 10px;
      color: $color-corp2;
      pointer-events: none;
    }
  }

  .filter-field-select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font: 500 16px/1 $corp-font1;
    color: $color-corp2;

    padding: 10px 40px 10px 15px;

    option {
      font: 500 18px/1 $corp-font1;
      color: $color-corp2;
    }
  }

  @include min-medium-tablet {
    .prices-filter-field {
      height: 40px;
    }
  }
  @include min-full-tablet {
    padding-top: 8px;
    display: inline-block;
    vertical-align: middle;
    width: 300px;
  }
}

.prices-module-container {
  overflow: auto;
}

.prices-module-list {
  .wrap-prices-list {
    padding-bottom: 15px;
    text-align: center;

    .prices-list-box {
      display: flex;
    }
  }

  @include min-full-tablet {
    margin: 0 -7px;
    .wrap-prices-list {
      font-size: 0;
      padding-bottom: 20px;
    }
  }
  @include min-full-desktop {
    margin: 0 -15px;
    .wrap-prices-list {
      padding-bottom: 35px;
    }
  }
}

.empty-message {
  display: none;
  padding-bottom: 30px;
  max-width: 850px;
  margin: 0 auto;

  &.show {
    display: block;
  }

  .wrap-empty-message {
    position: relative;
    border-radius: 4px;
    border: 1px solid $color-lines;
    text-align: center;
    padding: 20px;
    background-color: $color-background;
  }

  .empty-message-text {
    font: 500 22px/1 $corp-font-titles;
    color: $color-corp2;

    @include line(25, 22);
  }

  @include min-medium-tablet {
    .wrap-empty-message {
      padding: 30px 20px;
    }
    .empty-message-text {
      font-size: 26px;
    }
  }
}

.prices-list-element {
  position: relative;

  .prices-list-most-popular {
    font: 700 13px/1 $corp-font1;
    background-color: $color-corp2;
    padding: 3.5px 10px;
    text-transform: uppercase;
    color: #ffffff;
    position: absolute;
    left: -1px;
    top: -1px;
  }

  .wrap-prices-element {
    position: relative;
    border-radius: 4px;
    border: 1px solid $color-lines;
    text-align: center;
    width: 288px;
    margin-right: 35px;
    min-height: 288px;
    height: 100%;

    &.dark-background-element {
      background-color: $color-background-new;
    }

    @include min-medium-tablet {
      width: 420px;
      margin-right: 35px;
    }

    @include min-full-tablet {
      width: auto;
      margin-right: 0;
    }
  }

  .prices-element-tag {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 100%;
    @include transform(translateY(50%) translateX(-50%));
    font: 700 12px/1 $corp-font1;
    color: #fff;

    text-align: center;
    padding: 6px 0;
    background-color: $color-corp2;
    text-transform: uppercase;
    width: 130px;
  }

  @include min-full-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 7px 7px 0;
    margin-right: 0;
    @include full-desktop {
      .wrap-prices-element {
        min-height: 373px;
      }
    }
  }
  @include min-small-desktop {
    width: 50%;
    padding: 0 7px;
  }
  @include min-full-desktop {
    padding: 0 15px;
    .wrap-prices-element {
      min-height: 373px;
    }
  }
}

.prices-element-header {
  position: relative;
  padding: 15px 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.notResult {
    background-color: $color-background-grey;
  }

  &.rate0 {
    background-color: $color-starter;
  }

  &.rate1 {
    background-color: $color-corp2;
  }

  &.rate2 {
    background-color: $color-corp1;
  }

  .prices-element-title {
    font: 600 28px/1 $corp-font-titles;
    color: #ffffff;

    padding-bottom: 5px;
  }

  .prices-element-subtitle {
    font: 400 16px/1 $corp-font1;
    color: #ffffff;
  }

  @include min-medium-tablet {
    padding: 28px;
    @include full-tablet {
      &:after {
        left: 30px;
        width: calc(100% - 60px);
      }
    }
  }
  @include min-full-desktop {
    &:after {
      left: 30px;
      width: calc(100% - 60px);
    }
  }
}

.prices-element-info {
  position: relative;
  padding: 33.3px 10px;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid $color-lines-new2;
  min-height: 174px;
  height: 174px;

  &.prices-element-info-variable {
    min-height: 348px;
    height: 348px;
  }

  &.prices-element-info-popular {
    background-color: $color-background-new;
  }

  &.filtered-prices-element {
    border-radius: 4px;
  }

  .prices-info-container {
    display: flex;
    justify-content: center;
  }

  .prices-info-number {
    font: 500 28px $corp-font-titles;
    color: $color-corp1;

    display: flex;
  }

  .prices-info-number-dark {
    color: $color-corp1;
  }

  .prices-info-number-light {
    color: $color-corp2;
  }

  .prices-info-plus {
    padding: 0px 15px;
  }

  .prices-info-number-text {
    display: block;
    font: 400 16px $corp-font1;
    color: $color-corp2;

    padding-top: 5px;
    margin-left: 13px;
  }

  .prices-info-title {
    font: 600 25px/1 $corp-font-titles;
    color: $color-corp2;

    padding-bottom: 20px;
  }

  .prices-info-text {
    display: block;
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;

    padding-top: 5px;
  }

  .prices-info-phrase {
    font: 500 30px/1 $corp-font-titles;
    color: $color-corp1;

    @include line(33, 30);
  }

  @include min-medium-tablet {
    @include full-tablet {
      &:after {
        left: 30px;
        width: calc(100% - 60px);
      }
      .prices-info-phrase {
        font-size: 32px;
      }
    }
  }
  @include min-full-tablet {
    min-height: 223px;
    height: 223px;
    &.prices-element-info-variable {
      min-height: 446px;
      height: 446px;
    }
  }
  @include min-full-tablet {
    @include full-desktop {
      .prices-info-phrase {
        font-size: 25px;
        line-height: 1;
        max-width: 260px;
        margin: 0 auto;
      }
    }
  }
  @include min-full-desktop {
    padding: 40px 30px;
    &:after {
      left: 30px;
      width: calc(100% - 60px);
    }
    .prices-info-phrase {
      font-size: 42px;
    }
  }
}

.prices-element-foot {
  position: relative;
  padding: 15px 20px 45px;

  .prices-foot-text {
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;
    min-height: 25px;

    @include line(26, 16);
  }

  @include min-medium-tablet {
    @include full-tablet {
      padding: 20px 50px 45px;
      .prices-foot-text {
        font-size: 20px;
      }
    }
  }
  @include min-full-tablet {
    @include full-desktop {
      padding: 15px 20px 45px;
      min-height: 80px;
    }
  }
  @include min-full-desktop {
    min-height: 200px;
    .prices-foot-text {
      font-size: 20px;
    }
  }
}

.prices-foot-rate {
  padding-top: 20px;

  .prices-foot-rate_text {
    padding-top: 15px;
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;

    @include line(26, 16);
    border-top: 1px solid $color-lines;
  }

  .prices-foot-rate_text-price {
    padding-left: 10px;
    font: 400 30px/1 $corp-font-titles;
    color: $color-corp1;

    vertical-align: middle;
  }

  @include min-full-desktop {
    .prices-foot-rate_text {
      font-size: 20px;
    }
    .prices-foot-rate_text-price {
      font-size: 34px;
      padding-left: 15px;
    }
  }
}

.prices-foot-btn {
  width: 100%;
  padding-top: 35px;
  display: flex;
  justify-content: center;

  .prices-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 166.97px;
    background-color: $color-corp2;
    color: #ffffff;
    line-height: 24px;
  }

  .btn-corp-text {
    text-decoration: none;
    color: #ffffff;
    font: 600 16px $corp-font-titles;
  }
}

.prices-footer {
  margin-top: 15px;
  height: 168px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid $color-lines-new2;
  font: 400 16px/1 $corp-font1;
  color: $color-corp2;
  line-height: 28.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.prices-module-extra {
  display: flex;

  @include min-medium-tablet {
    .prices-extra-title {
      left: 20px;
    }
  }

  @include min-full-desktop {
    margin: 0 -15px;
    .prices-extra-title {
      left: 60px;
    }
  }

  .prices-extra-title {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 10px;
    @include transform(translateY(-50%));
    background-color: #fff;
    padding: 0 10px;
    font: 500 40px/1 $corp-font-titles;
    color: $color-corp2;
  }
}

.prices-extra-list {
  width: 288px;
  margin-right: 35px;

  @include min-medium-tablet {
    width: 420px;
    margin-right: 35px;
    .wrap-extra-element {
      padding: 20px 0 20px 15px;
    }
  }
  @include min-full-tablet {
    width: auto;
    font-size: 0;
    margin-right: 0;
  }
}

.prices-extra-element {
  &:last-child {
    @include full-tablet {
      .wrap-extra-element {
        border-bottom: none;
      }
    }
  }

  .wrap-extra-element {
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid $color-lines;
  }

  .extra-element-text {
    position: relative;
    font: 400 16px/1 $corp-font1;
    color: $color-corp2;
    text-align: center;

    &.not-available {
      text-decoration: line-through;
      color: $color-not-available;
    }
  }

  @include min-medium-tablet {
    width: 420px;
    margin-right: 35px;
    .wrap-extra-element {
      padding: 20px 0 20px 15px;
    }
  }
  @include min-full-tablet {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    &:nth-child(1) {
      .wrap-extra-element {
        border-top: none;
      }
    }
    .wrap-extra-element {
      border-top: 1px solid $color-lines;
      border-bottom: none;
    }
  }
}

.contact-page {
  @include min-medium-tablet {
    .wrap-contact-page {
      padding-bottom: 60px;
    }
  }
}

.contact-page-header {
  .wrap-contact-header {
    padding-top: 30px;

    .contact-header-title {
      font-family: $corp-font-titles;
      font-size: 28px;
      font-weight: 600;
      color: $color-corp2;
    }
  }

  @include min-medium-tablet {
    .wrap-contact-header {
      position: relative;
      padding: 50px 0 0;
      text-align: left;

      .contact-header-btn {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
      }
    }
  }
  @include min-full-tablet {
    .wrap-contact-header {
      padding: 0;
    }
  }
}

.contact-page-info {
  &.pre-table {
    .wrap-contact-info {
      padding-bottom: 0;

      .contact-info-title {
        padding-bottom: 0;
      }
    }
  }

  .wrap-contact-info {
    padding: 10px 0 20px;

    .contact-info-title {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 700;
      color: $color-corp2;

      @include line(20, 16);
      padding: 20px 0 10px;
    }

    .contact-info-text {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 400;
      color: $color-corp2;

      @include line(32, 20);
      white-space: pre-line;

      &.subtitle {
        font-weight: 700;
        padding: 30px 0 15px;
      }

      a {
        color: $color-corp2;
        font-weight: 700;
      }
    }
  }

  @include min-medium-tablet {
    .wrap-contact-info {
      padding: 20px 0 30px;

      .contact-info-title {
        font-size: 18px;
        padding-bottom: 20px;
      }
    }
  }
}

.contact-page-form {
  .wrap-contact-form {
    padding: 10px 0 35px;

    .contact-form-title {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 700;
      color: $color-titles;
      text-transform: uppercase;
    }
  }

  @include min-medium-tablet {
    .wrap-contact-form {
      padding: 0;
    }
  }
}

.contact-form-fields {
  .wrap-contact-fields {
    padding: 10px 0 0 20px;
  }

  @include min-medium-tablet {
    .wrap-contact-fields {
      padding: 20px 0 0 20px;
      font-size: 0;
      margin: 0 -25px;
    }
  }
}

.contact-fields-element {
  padding-bottom: 25px;
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 25px 40px;
    &.full {
      width: 100%;
    }
  }
}

.contact-form-foot {
  .wrap-form-foot {
    //display: flex;
    justify-content: space-between;
    padding: 0 0 0 20px;
  }

  @include min-medium-tablet {
    .wrap-form-foot {
      font-size: 0;
    }
  }

  @include mobile {
    .wrap-form-foot {
      display: flex;
      flex-direction: column;
    }
  }
}

.form-foot-checks {
  .wrap-foot-checks {
    padding-bottom: 10px;
    margin-right: 20px;

    .must-element {
      padding-bottom: 10px;
    }

    .checkbox-element {
      padding-bottom: 10px;
    }
  }

  @include min-medium-tablet {
    //display: inline-block;
    vertical-align: top;
    width: calc(100% - 313px);
    .wrap-foot-checks {
      padding-bottom: 0;
    }
  }
}

.must-element {
  .must-element-text {
    position: relative;
    font-family: $corp-font1;
    font-size: 15px;
    font-weight: 400;
    color: $color-corp2;

    @include line(25, 15);
    padding-left: 25px;

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 6px;
      width: 6px;
      height: 6px;
      background-color: $color-corp2;
      border-radius: 50%;
    }
  }
}

.form-foot-btns {
  .wrap-foot-btns {
    text-align: left;
    padding-top: 40px;
  }

  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
  }
}

.contact-page-details {
  .wrap-contact-details {
    padding: 30px 0;
  }
}

.contact-details-element {
  .wrap-details-element {
    padding: 35px 0;
    border-bottom: 1px solid $color-lines;
  }

  &:first-child {
    .wrap-details-element {
      border-top: 1px solid $color-lines;
    }
  }

  @include min-medium-tablet {
    .wrap-details-element {
      font-size: 0;
      padding: 40px 0;
    }
  }
}

.details-element-header {
  .wrap-details-header {
    .details-header-title {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 700;
      color: $color-corp2;
      text-transform: uppercase;
    }
  }

  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 275px;
    .wrap-details-header {
    }
  }
}

.details-element-content {
  .wrap-details-content {
    .details-content-text {
      display: table;
      font: 400 16px/1 $corp-font1;

      @include transition($Stransition);

      small {
        font-weight: 500;
        display: inline-block;
        vertical-align: baseline;
        padding-left: 5px;
      }

      &.link {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0%;
          height: 1px;
          background-color: $color-corp1;
          @include transition($Stransition);
        }

        @include hovers {
          color: $color-corp1;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 275px);
  }
}

.popup-module {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  @include transition($Stransition);
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;

  .video-iframe {
    width: 100%;
    height: 100%;
  }

  @include min-medium-tablet {
    .video-iframe {
      width: 500px;
      height: 300px;
    }
  }

  @include min-small-desktop {
    .video-iframe {
      width: 800px;
      height: 400px;
    }
  }

  @include min-full-desktop {
    .video-iframe {
      width: 1020px;
      height: 600px;
    }
  }

  &.full {
    &:before {
      background-color: #fff;
      pointer-events: none;
    }

    .wrap-popup-module {
      .popup-module-backmask {
        pointer-events: none;
      }
    }

    .popup-module-box {
      max-width: initial;

      .wrap-popup-box {
        max-width: initial;
      }

      &.video {
        max-width: fit-content !important;
      }
    }

    @include min-medium-tablet {
      &.open {
        .popup-module-box {
          .popup-box-close {
            @include transform(translateX(0) scale(1));
          }
        }
      }
      .popup-module-box {
        .popup-box-close {
          right: 20px;
          border-color: $color-corp1;

          &:before {
            color: $color-corp1;
          }

          @include hovers {
            background-color: $color-corp1;
            &:before {
              color: #fff;
            }
          }
        }
      }
    }
    @include min-full-tablet {
      .popup-module-box {
        .wrap-popup-box {
          max-width: 820px;
          margin: 0 auto;
        }

        .popup-box-close {
          top: 20px;
          right: calc(50% - 410px);
        }
      }
    }
    @include min-full-desktop {
      .popup-module-box {
        .wrap-popup-box {
          max-width: 1060px;
        }

        &.video {
          max-width: fit-content !important;
        }

        .popup-box-close {
          top: 20px;
          right: calc(50% - 530px);
        }
      }
    }
  }

  &.open {
    @include medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-module-box {
        .popup-box-close {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }

        .wrap-popup-box {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
    @include min-medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-module-box {
        opacity: 1;
        @include transform(translateY(0px));
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;

        .popup-box-close {
          opacity: 1;
          @include transform(translateY(-100%) translateX(100%) scale(1));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    @include transform(translateY(100%));
    @include transition($Stransition);
    transition-delay: 0.4s;
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
  }

  .wrap-popup-module {
    position: relative;
    height: 100%;
    font-size: 0;
    text-align: center;

    &:before {
      content: "";
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
      opacity: 0;
      pointer-events: none;
    }

    .popup-module-backmask {
      display: none;
    }
  }

  @include min-medium-tablet {
    &:before {
      background-color: rgba(#000, 0.7);
    }
    .wrap-popup-module {
      .popup-module-backmask {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: zoom-out;
      }
    }
  }
}

.popup-module-box {
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  &.video {
    max-width: fit-content;
  }

  .popup-box-close {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: 20px;
    opacity: 0;
    @include transform(translateY(-30px));
    @include transition($Stransition);
    @include hovers {
      background-color: #fff;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
    }
  }

  .wrap-popup-box {
    opacity: 0;
    @include transform(translateY(30px));
    @include transition($Stransition);
    padding: 60px 35px;
    max-width: 370px;
    margin: 0 auto;
    max-height: 100vh;
    overflow: auto;

    &.align-left {
      text-align: left;
    }

    .popup-box-icon {
      display: inline-block;
      padding-bottom: 15px;
      font-size: 28px;
      color: $color-corp2;

      &.icon-guests {
        font-size: 30px;
      }
    }

    .popup-box-title {
      font-family: $corp-font1;
      font-size: 19px;
      font-weight: 400;
      color: $color-titles;

      padding-bottom: 10px;
    }

    .popup-box-subtitle {
      font-family: $corp-font-titles;
      font-size: 28px;
      font-weight: 600;
      color: $color-titles;

      padding-bottom: 50px;

      &:after {
        content: "";
        display: block;
        margin: 25px auto 0;
        width: 50px;
        height: 1px;
        background-color: $color-corp2;
      }
    }

    .popup-box-text {
      font-family: $corp-font1;
      font-size: 17px;
      font-weight: 400;
      color: $color-corp2;

      @include line(23, 18);
    }
  }

  @include min-medium-tablet {
    position: relative;
    z-index: 2;
    max-width: 400px;
    background-color: #fff;
    border-radius: 4px;
    opacity: 0;
    @include transform(translateY(30px));
    @include transition($Stransition);
    &.bigger {
      max-width: 564px;
    }
    .popup-box-close {
      top: -10px;
      right: -20px;
      @include transform(translateY(-100%) translateX(100%) scale(0));
      border-color: #fff;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;

      &.video {
        right: 40px !important;
      }

      &:before {
        color: #fff;
      }

      &:after {
        background-color: #fff;
      }

      @include hovers {
        &:before {
          color: $color-corp1;
        }
      }
    }
    .wrap-popup-box {
      opacity: 1;
      @include transform(none);
      padding: 50px;
      max-width: initial;
      max-height: 85vh;

      .popup-box-title {
        padding-bottom: 20px;
      }

      .popup-box-subtitle {
        padding-bottom: 30px;
      }
    }
  }
  @include min-full-tablet {
    &.bigger {
      max-width: 895px;
    }
  }
}

.popup-box-languages {
  .btn-languages {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      .btn-corp-text {
        background-color: $color-corp2;
        color: #fff;
        border-color: transparent;
      }
    }

    .btn-corp-text {
      min-width: 200px;
      border: 1px solid $color-corp2;
      background-color: #ffffff;
      color: $color-corp2;
    }
  }

  @include min-medium-tablet {
    .btn-languages {
      margin: 0 auto 25px;

      .btn-corp-text {
        min-width: 260px;
      }
    }
  }
}

.popup-box-newsletter {
  .wrap-box-newsletter {
    padding-top: 20px;
    border-top: 1px solid $color-lines;

    .popup-newsletter-input {
      padding-top: 25px;
    }

    .popup-newsletter-checkbox {
      padding-top: 20px;
    }
  }
}

.popup-box-list {
  padding-top: 20px;
}

.popup-list-element {
  .wrap-list-element {
    position: relative;
    padding: 23px 20px 21px;
    text-align: left;
    border-top: 1px solid $color-lines;

    &:before {
      content: "\e903";
      position: absolute;
      left: 0;
      top: 28px;
      font-family: "icomoon";
      font-size: 7px;
      color: $color-corp2;
    }

    .list-element-text {
      font-family: $corp-font1;
      font-size: 18px;
      font-weight: 400;
      color: $color-corp2;
    }
  }
}

.popup-notification {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  @include transition($Stransition);
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;

  &.open {
    @include medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-notification-box {
        .popup-notification-close {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }

        .wrap-notification-box {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
    @include min-medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-notification-box {
        opacity: 1;
        @include transform(translateY(0px));
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;

        .popup-notification-close {
          opacity: 1;
          @include transform(translateY(0%) translateX(100%) scale(1));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;

          &:after {
            background-color: #fff;
          }

          @include hovers {
            &:before {
              color: $color-corp1;
            }
          }
        }

        .wrap-notification-box {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.7);
    @include transform(translateY(100%));
    @include transition($Stransition);
    transition-delay: 0.4s;
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
  }

  .wrap-popup-notification {
    position: relative;
    height: 100%;
    font-size: 0;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
      opacity: 0;
      pointer-events: none;
    }

    .popup-notification-backmask {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: zoom-out;
    }
  }
}

.popup-notification-box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 0 25px;

  .popup-notification-close {
    position: absolute;
    z-index: 2;
    bottom: calc(100% + 20px);
    right: 25px;
    opacity: 0;
    @include transform(translateY(-30px));
    @include transition($Stransition);
    border-color: #fff;

    &:before {
      color: #fff;
    }

    @include hovers {
      background-color: #fff;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
    }
  }

  .wrap-notification-box {
    opacity: 0;
    @include transform(translateY(30px));
    @include transition($Stransition);
    padding: 60px 25px 25px;
    margin: 0 auto;
    max-height: 80vh;
    overflow: auto;
    background-color: #fff;
    border-radius: 4px;

    .popup-notification-tag {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color-corp1, 0.8);
      padding: 8px 30px 7px;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }
  }

  @include min-small-tablet {
    padding: 0 40px;
    .popup-notification-close {
      right: 40px;
    }
  }
  @include min-medium-tablet {
    padding: 0;
    max-width: 530px;
    .popup-notification-close {
      right: 0;
    }
    .wrap-notification-box {
      padding: 60px 60px 40px;
    }
  }
}

.popup-notification-info {
  .wrap-notification-info {
    position: relative;
    padding-bottom: 30px;
    text-align: left;

    .notification-info-title {
      font-family: $corp-font-titles;
      font-size: 26px;
      font-weight: 500;
      color: $color-titles;

      @include line(28, 26);
    }

    .notification-info-subtitle {
      font-family: $corp-font1;
      font-size: 12px;
      font-weight: 700;
      color: $color-corp2;
      text-transform: uppercase;

      @include line(20, 15);
      padding-bottom: 20px;
    }

    .notification-info-text {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 400;
      color: $color-corp2;

      @include line(24, 16);

      &:before {
        content: "";
        display: block;
        width: 50px;
        height: 1px;
        background-color: $color-corp2;
        margin: 25px 0;
      }
    }
  }

  @include min-medium-tablet {
    .wrap-notification-info {
      .notification-info-title {
        font-size: 32px;
      }

      .notification-info-date {
        font-size: 14px;
      }

      .notification-info-subtitle {
        font-size: 14px;
      }

      .notification-info-text {
        font-size: 18px;
      }
    }
  }
  @include min-small-desktop {
    width: 400px;
  }
}

.popup-notification-price {
  .wrap-notification-price {
    overflow: hidden;
    padding-bottom: 25px;

    .notification-price-since {
      float: left;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 700;
      color: rgba($color-corp2, 0.8);
      text-transform: uppercase;
    }

    .notification-price-number {
      float: right;
      font-size: 0;

      .price-number {
        display: inline-block;
        vertical-align: middle;
        font-family: $corp-font1;
        font-size: 50px;
        font-weight: 400;
        color: $color-corp2;
        line-height: 0.8;
      }

      .price-coin {
        display: inline-block;
        vertical-align: middle;
        font-family: $corp-font1;
        font-size: 35px;
        font-weight: 400;
        color: $color-corp2;
        padding-left: 5px;
      }
    }
  }

  @include min-medium-tablet {
    .wrap-notification-price {
      .notification-price-number {
        .price-number {
          font-size: 64px;
        }

        .price-coin {
          font-size: 44px;
        }
      }
    }
  }
}

.popup-notification-btns {
  text-align: center;

  .notification-btns-element {
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px 15px;

    &:first-child {
      padding-left: 0;
    }

    &:nth-child(2) {
      padding-right: 0;
    }
  }

  @include min-medium-tablet {
    overflow: hidden;
    .notification-btns-element {
      .btn-corp {
        .btn-corp-text {
          min-width: 260px;
        }
      }

      .btn-linker {
        padding-top: 10px;
      }

      &:first-child {
        float: left;
      }

      &:nth-child(2) {
        float: right;
      }
    }
  }
  @include min-small-desktop {
    .notification-btns-element {
      .btn-corp {
        .btn-corp-text {
          min-width: 250px;
        }
      }
    }
  }
  @include small-tablet {
    .notification-btns-element {
      padding: 0 0 15px;
    }
  }
}

.popup-notification-img {
  display: none;
  @include min-small-desktop {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    .wrap-notification-img {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .notification-img-photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
      }
    }
  }
}

.aside-pagenav {
  display: none;
}

@include min-full-tablet {
  .aside-pagenav {
    display: block;
    position: fixed;
    z-index: 8;
    top: 50%;
    right: 0;
    @include transform(translateY(-50%));
    @include transition($Stransition);

    &.stop {
      opacity: 0;
      pointer-events: none;
    }

    .wrap-aside-pagenav {
      padding-right: 28px;
    }
  }
  .aside-pagenav-list {
  }
  .aside-pagenav-element {
    padding: 7px;
    cursor: pointer;

    &.active {
      .pagenav-element-tag {
        opacity: 1;
        @include transform(translateY(-50%) translateX(0px) scale(1));
      }

      .pagenav-element-dot {
        background-color: $color-corp2;
        border-color: transparent;

        &:before {
          opacity: 0;
        }
      }
    }

    &:hover {
      .pagenav-element-tag {
        opacity: 1;
        @include transform(translateY(-50%) translateX(0px) scale(1));
      }
    }

    .wrap-pagenav-element {
      position: relative;
    }

    .pagenav-element-tag {
      position: absolute;
      top: 50%;
      right: 26px;
      @include transform(translateY(-50%) translateX(5px) scale(0.8));
      transform-origin: center right;
      padding: 9px 5px;
      border-radius: 2px;
      background-color: $color-corp2;
      font: 500 13px/1 $corp-font1;

      text-align: center;
      color: #fff;
      width: 130px;
      opacity: 0;
      @include transition($Stransition);
      pointer-events: none;

      &:after {
        content: "\e90f";
        position: absolute;
        top: calc(50% - 4px);
        left: calc(100% - 1px);
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
      }
    }

    .pagenav-element-dot {
      position: relative;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid rgba(#00785a, 0.4);
      @include transition($Stransition);

      &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 50%;
        border: 2px solid rgba(#fff, 0.4);
        @include transition($Stransition);
      }
    }
  }
}

.form-message {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  letter-spacing: 0.04em;
  line-height: 166.66667%;
  text-align: left;
}

.form-message-success {
  color: $color-corp1;
}

.form-message-error {
  color: red;
}

.btn-corp-text[disabled] {
  cursor: default;
  color: $color-corp1-disabled !important;
  border-color: $color-corp1-disabled !important;

  &.btn-corp-text:hover {
    cursor: default;
    background-color: white !important;
  }
}
