//* FONTS *//
$corp-font-titles: "Poppins", sans-serif;
$corp-font1: "Poppins", sans-serif;

//* COLORS *//
$color-background: #EAFCF2;
$color-background-grey: #D3D3D3;
$color-background-new: #EFF1F9;
$color-lines: #D2D1D1;
$color-lines-new: #ABAFC0;
$color-lines-new2: #DCDDEC;
$color-lines-new3: #CACCE2;
$color-titles: #FF6136;
$color-not-available: #A1A1A1;
$color-text-new: #C0C3D0;
$color-corp1: #FF6136;
$color-corp2: #2E3862;
$color-corp1-disabled: #808080;
$color-starter: #7096FF;

//* TRANSITION *//
$Ltransition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s;
$Mtransition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.85s;
$Stransition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.7s;


//* SASS FUNCTIONS *//

//* MEDIA QUERYS *//
$mobile: 375px;
$small-tablet: 540px;
$medium-tablet: 768px;
$full-tablet: 1024px;
$small-desktop: 1280px;
$medium-desktop: 1366px;
$full-desktop: 1680px;

@mixin full-desktop {
  @media (max-width: #{$full-desktop - 1px}) {
    @content;
  }
}

@mixin medium-desktop {
  @media (max-width: #{$medium-desktop - 1px}) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: #{$small-desktop - 1px}) {
    @content;
  }
}

@mixin full-tablet {
  @media (max-width: #{$full-tablet - 1px}) {
    @content;
  }
}

@mixin hovers {
  @media (min-width: #{$small-desktop}) {
    &:hover, &:focus {
      @content;
    }
  }
}

@mixin medium-tablet {
  @media (max-width: #{$medium-tablet - 1px}) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: #{$small-tablet - 1px}) {
    @content;
  }
}

@mixin min-mobile {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile - 1px}) {
    @content;
  }
}

@mixin min-full-desktop {
  @media (min-width: #{$full-desktop}) {
    @content;
  }
}

@mixin min-medium-desktop {
  @media (min-width: #{$medium-desktop}) {
    @content;
  }
}

@mixin min-small-desktop {
  @media (min-width: #{$small-desktop}) {
    @content;
  }
}

@mixin min-full-tablet {
  @media (min-width: #{$full-tablet}) {
    @content;
  }
}

@mixin min-medium-tablet {
  @media (min-width: #{$medium-tablet}) {
    @content;
  }
}

@mixin min-small-tablet {
  @media (min-width: #{$small-tablet}) {
    @content;
  }
}

@mixin min-mobile {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

//* IMG-VIDEO RATIO      (@include responsive-ratio(16,9);) *//
@mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote(( $y / $x ) * 100 + '%');
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

//* LINE-HEIGHT *//
@mixin line($pt, $sz) {
  line-height: 100% * ($pt/$sz);
}

//* LETTER-SPACING *//
@mixin space($pt) {
  letter-spacing: ($pt/1000);
}

//* PSEUDO ELEMENTS      (@include pseudo) *//
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

//* TRIANGLE ICON      (@include css-triangle(grey, up, 6px);) *//
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}


//* ALL BROWSERS *//
@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
  -ms-appearance: $appearance;
  -o-appearance: $appearance;
  appearance: $appearance;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

//* @include input-placeholder {color: $grey;} *//
@mixin input-placeholder() {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-o-input-placeholder {
    @content;
  }
}

@mixin box-shadow() {
  box-shadow: 2px 2px 2px rgba($color-corp2, 0.4);
}