//* GLOBAL THEME */
@charset "UTF-8";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $corp-font1;
  font-size: 14px;
  line-height: 1;
  color: $color-corp2;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  &.noscroll, &.lb-disable-scrolling {
    overflow: hidden;
    @include min-small-desktop {
      padding-right: 17px;
    }
  }

  &.resize-on {
    * {
      @include transition(none !important);
    }
  }
}

figure {
  margin: 0;
}

p, a, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: normal;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

small {
  font-size: 80%;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
  border: none;
  resize: none;
}

button,
input {
  padding: 0;
  line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  border: none;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

i {
  font-family: "icomoon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}


.ui-widget.ui-widget-content {
  border-color: $color-corp1 !important;
}

.ui-widget-header {
  background-color: $color-corp1 !important;
  border-color: $color-corp1 !important;
}

.ui-datepicker-title {
  font-family: $corp-font1 !important;
  color: #fff !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.ui-datepicker-calendar th {
  font-family: $corp-font1 !important;
  color: $color-corp2 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  font-family: $corp-font1 !important;
  font-size: 16px !important;
  border: 1px solid $color-corp2 !important;
  background: #fff !important;
  font-weight: normal !important;
  color: $color-corp2 !important;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
  border: 1px solid $color-corp1 !important;
  background: $color-corp1 !important;
  font-weight: normal !important;
  color: #fff !important;
}

.ui-widget-header .ui-icon {
  background-image: url("../assets/img/ui-icons_ffffff_256x240.png") !important;
}